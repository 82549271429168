<template>
  <div>
    <a-modal
      width="1220px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑客户套餐"
      @ok="handleSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-row>
            <!--基本信息， 周期容量设置-->
            <a-col :span="12">
              <span>基本信息</span>
              <a-form-item label="套餐名称">
                <a-input
                  v-decorator="['name', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入套餐名称' },
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="基础套餐名称"
                class="simiot-display-item"
              >
                {{ data.base_product_name }}
              </a-form-item>

              <a-form-item
                label="运营商种类"
                class="simiot-display-item"
              >
                {{ data.carrier_type }}
              </a-form-item>

              <a-form-item
                label="流量属性"
                class="simiot-display-item"
              >
                {{ data.traffic_property }}
              </a-form-item>

              <a-form-item
                label="用户业务模式"
                class="simiot-display-item"
              >
                {{ data.user_business_type }}
              </a-form-item>

              <a-form-item
                label="套餐周期"
                class="simiot-display-item"
              >
                {{ data.service_cycle }}
              </a-form-item>

              <a-form-item
                label="套餐容量"
                class="simiot-display-item"
              >
                {{ data.package_capacity }}
              </a-form-item>

              <a-form-item
                label="语音"
                class="simiot-display-item"
              >
                {{ data.voice_capacity }}
              </a-form-item>

              <a-form-item label="套餐描述：">
                <a-textarea
                  v-decorator="['product_description', {
                    rules: [
                      { max: 200, message: '最多200个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <div v-show="isCanSetAllowRetailOrSelfSubscribe">
                <span>零售设置</span>

                <a-form-item label="允许零售">
                  <a-checkbox
                    :checked="isAllowRetail"
                    v-decorator="['is_allow_retail']"
                    @change="changeIsAllowRetail"
                  /> 允许移动端订购
                </a-form-item>

                <a-form-item label="零售价设置者" v-show="isAllowRetail">
                  <a-radio-group
                    disabled
                    v-decorator="['retail_operator',{
                      initialValue: data.retail_operator,
                      rules: [
                        { required: isAllowRetail, message: '请选择零售价设置者' },
                      ]
                    }]"
                  >

                    <a-radio value="platform">
                      平台
                    </a-radio>
                    <a-radio value="user">
                      零售商
                    </a-radio>
                  </a-radio-group>
                  <font style="color:red;">(继承自基础套餐)</font>
                </a-form-item>

                <span v-show="false">自定义功能</span>

                <a-form-item label="限制订购周期数" v-show="false">
                  <a-input-group compact>
                    <a-form-item :style="{ display: 'inline-block', width: '15%', marginBottom: 0 }">
                      <a-checkbox
                        :checked="isLimitSubscribeCycle"
                        v-decorator="['is_limit_subscribe_cycle']"
                        @change="changeIsLimitSubscribeCycle"
                      />
                    </a-form-item>

                    <a-form-item
                      :style="{ display: 'inline-block', width: '85%', marginBottom: 0 }"
                      v-if="isLimitSubscribeCycle"
                    >
                      <a-space>
                        <a-input-number
                          style="width: 200px"
                          :min="1"
                          :max="10000000"
                          :step="1"
                          :precision="0"
                          v-decorator="['limit_subscribe_cycle', {
                            initialValue: data.limit_subscribe_cycle == -1 ? undefined : data.limit_subscribe_cycle,
                            rules: [
                              { required: isLimitSubscribeCycle, message: '请输入订购周期数' }
                            ]
                          }]"
                        />
                        <span>个周期</span>
                      </a-space>
                    </a-form-item>
                  </a-input-group>
                </a-form-item>
              </div>

              <div>
                <span>其它设置</span>
                <a-form-item :label="`可订加油包`" v-show="isCanSetAddonProduct">
                  <a-select
                    mode="multiple"
                    placeholder="允许此套餐卡订购的加油包"
                    v-decorator="['addon_product_ids', {
                      rules: [{ required: false, message: '请选择加油包' }]
                    }]"
                    allow-clear
                  >
                    <a-select-option
                      v-for="option in addonProductOptions"
                      :key="option.product_id"
                      :value="option.product_id"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item label="允许自订" v-show="isCanSetAllowRetailOrSelfSubscribe">
                  <a-checkbox
                    :checked="isAllowSelfSubscribe"
                    v-decorator="['is_allow_self_subscribe']"
                    @change="changeIsAllowSelfSubscribe"
                  /> 允许CMP订购
                </a-form-item>

                <a-form-item label="允许超池" v-show="isCanSetAllowExceedPackage">
                  <a-checkbox
                    :checked="IsAllowExceedPool"
                    v-decorator="['is_allow_exceed_pool']"
                    @change="changeIsAllowExceedPool"
                  /> 允许套餐流量池超池使用
                </a-form-item>
              </div>
            </a-col>

            <!--价格设置-->
            <a-col :span="12">
              <span>价格设置</span>
              <div class="simiot-table-operation-buttons">
                <a-space>
                  <a-button type="primary" @click="addSetPriceItem" v-if="!isSaleLogin"> 新增 </a-button>
                </a-space>
              </div>

              <a-row
                :gutter="24"
                style="padding-left: 2px"
              >
                <a-col
                  :span="favorablePriceSpanSize"
                  v-for="label in favorablePriceLabels"
                  :key="label"
                >
                  {{ label }}
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="(k, index) in form.getFieldValue('keys')"
                :key="k"
              >
                <a-col :span="favorablePriceSpanSize">
                  <a-form-item>
                    <a-input-number
                      :min="1"
                      :max="10000000"
                      style="width: 120px"
                      :disabled="isSaleLogin || isMerchantSales"
                      @change="validateFavorablePriceCycles"
                      v-decorator="[`agents_product_favorable_prices[${k}].cycles`, {
                        initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].cycles,
                        rules: [
                          { required: true, message: '请输入周期数' },
                          { type: 'integer', message: '请输入 1 - 10000000 之间的整数' },
                          { validator: (rule, value, callback) => checkCycles(rule, value, callback, k, index) }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      :disabled="isSaleLogin || isMerchantSales"
                      v-decorator="[`agents_product_favorable_prices[${k}].floor_price`, {
                        initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].floor_price,
                        rules: [
                          { required: true, message: '请输入底价' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize">
                  <a-form-item>
                    <a-space>
                      <a-input-number
                        :min="0"
                        :max="10000000"
                        :step="0.01"
                        :precision="2"
                        style="width: 120px"
                        :disabled="isMerchantSales"
                        v-decorator="[`agents_product_favorable_prices[${k}].distributor_price`, {
                          initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].distributor_price,
                          rules: [
                            { required: true, message: '请输入客户价' },
                            { validator: checkDistributorPrice }
                          ]
                        }]"
                      />

                      <a-icon
                        v-show="!isPlatformRetailOperator && form.getFieldValue('keys').length > 1 && !isSaleLogin"
                        :style="{ display: 'inline-block'}"
                        class="dynamic-delete-button"
                        type="minus-circle-o"
                        :disabled="form.getFieldValue('keys').length === 1"
                        @click="() => removeSetPriceItem(k)"
                      />
                    </a-space>
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize" v-show="isPlatformRetailOperator">
                  <a-input-group compact>
                    <a-form-item>
                      <a-space>
                        <a-input-number
                          :min="0"
                          :step="0.01"
                          :max="10000000"
                          :precision="2"
                          style="width: 120px"
                          v-decorator="[`agents_product_favorable_prices[${k}].retail_price`, {
                            initialValue: agentsProductFavorablePrices[k] && agentsProductFavorablePrices[k].retail_price,
                            rules: [
                              { type: 'number', message: '请输入 1 - 10000000 之间的数字' },
                              { validator: (rule, value, callback) => checkRetailPrice(rule, value, callback, k) }
                            ]
                          }]"
                        />

                        <a-icon
                          :style="{ display: 'inline-block'}"
                          class="dynamic-delete-button"
                          type="minus-circle-o"
                          @click="() => removeSetPriceItem(k)"
                          :disabled="form.getFieldValue('keys').length === 1"
                          v-show="form.getFieldValue('keys').length > 1 && !isSaleLogin"
                        />
                      </a-space>
                    </a-form-item>
                  </a-input-group>
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
                <a-col :span="8">
                  超额计费单位*
                </a-col>

                <a-col :span="8">
                  底价(元)*
                </a-col>

                <a-col :span="8">
                  客户价(元)*
                </a-col>

              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
                <a-col :span="8">
                  <a-input-group compact>
                    <a-form-item>
                      <a-space>
                        <a-input-number
                          style="width: 120px"
                          :min="1"
                          :max="10000000"
                          :disabled="isSaleLogin || isMerchantSales"
                          v-decorator="['excess_rate_quantity', {
                            rules: [
                              { required: isShowExcessRateItem, message: '请输入超额计费单位' },
                              { type: 'integer', message: '请输入 1 - 10000000 之间的整数' }
                            ]
                          }]"
                        />
                        <span>{{ excessRateQuantityUnit }}</span>
                      </a-space>
                    </a-form-item>
                  </a-input-group>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      :disabled="isSaleLogin || isMerchantSales"
                      v-decorator="['excess_floor_price', {
                        rules: [
                          { required: isShowExcessRateItem, message: '请输入底价' },
                          { type: 'number', message: '请输入 1 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px;"
                      :disabled="isMerchantSales"
                      v-decorator="['excess_distributor_price', {
                        rules: [
                          { required: isShowExcessRateItem, message: '请输入客户价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' },
                          { validator: checkExcessDistributorPrice }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowStopItem">
                <a-col :span="8">
                  停机保号底价(元)*
                </a-col>

                <a-col :span="8">
                  停机保号客户价(元)*
                </a-col>

              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowStopItem">
                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      :disabled="isSaleLogin"
                      v-decorator="['stop_floor_price', {
                        rules: [
                          { required: isShowStopItem, message: '请输入底价' },
                          { type: 'number', message: '请输入 1 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px;"
                      v-decorator="['stop_distributor_price', {
                        rules: [
                          { required: isShowStopItem, message: '请输入客户价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' },
                          { validator: checkStopDistributorPrice }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <span class="item-title">卡片设置</span>
              <a-row style="margin: 10px 0 10px 9px" :gutter="24">
                <a-col :span="8">
                  卡片材质*
                </a-col>
                <a-col :span="8">
                  底价(元)*
                </a-col>
                <a-col :span="8">
                  客户价(元)*
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="(agentsProductsCardType, index) in agentsProductsCardTypes"
                :key="'agentsProductsCardType-'+ index"
              >

                <a-col :span="8">
                  <a-form-item>
                    <a-row style="width: 150px">
                      <a-col :span="4">
                        <a-checkbox-group
                          :disabled="isSaleLogin"
                          v-decorator="[`agents_products_card_types[${index}].card_type_id`,
                                        { initialValue: [agentsProductsCardType.agents_products_card_type_id] }]"
                          @change="(checked) => changeCardType(checked, index)"
                          style="width: 155px"
                        >
                          <a-checkbox :value="agentsProductsCardType.card_type_id" />
                        </a-checkbox-group>
                      </a-col>
                      <a-col :span="20">
                        {{ agentsProductsCardType.card_type_name }}
                      </a-col>
                    </a-row>
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      :disabled="isSaleLogin"
                      v-decorator="[`agents_products_card_types[${index}].floor_price`, {
                        initialValue: agentsProductsCardType.id > 0 ? agentsProductsCardType.floor_price : undefined,
                        rules: [
                          { required: cardTypePriceRequired[index], message: '请输入底价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      style="width: 120px"
                      v-decorator="[`agents_products_card_types[${index}].price`, {
                        initialValue: agentsProductsCardType.id > 0 ? agentsProductsCardType.price : undefined,
                        rules: [
                          { required: cardTypePriceRequired[index], message: '请输入客户价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' },
                          { validator: checkCardTypePrice }
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAddonProductOptions } from '@/api/common_options.js'
import { findAgentsProductForm, updateAgentsProduct, checkAgentsProductPrices } from '@/api/agents_product'

export default {
  name: 'EditAgentsProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      excessRateQuantityUnit: 'MB', // 超额计费单位
      isShowExcessRateItem: true, // 是否显示设置超额参数一项
      isShowStopItem: false, // 是否显示设置停机保号参数一项
      userBusinessType: 'custom_month',
      data: {},
      agentsProductFavorablePrices: {},
      isMerchantSales: false,
      isCanSetAllowRetailOrSelfSubscribe: false, // 是否可设置零售配置
      isAllowRetail: false, // 是否允许零售
      isCanSetAllowExceedPackage: false, // 是否可设置超池
      isLimitSubscribeCycle: false, // 是否限制订购周期数
      isAllowSelfSubscribe: false, // 是否允许自定套餐
      isCanSetAddonProduct: false, // 是否允许配置可订购加油包
      IsAllowExceedPool: false, // 是否允许超池使用
      favorablePriceLabels: ['周期数*', '底价(元)*', '客户价(元)*'], // 价格设置label
      favorablePriceSpanSize: 8, // 价格设置span大小
      submitting: false,
      spinning: false,
      priceIndex: 1,
      form: this.$form.createForm(this, { name: 'agents_product' }),
      agentsProductsCardTypes: {},
      isPlatformRetailOperator: false, // 零售价设置者是否是平台
      addonProductOptions: [], // 可用加油包
      loadingAddonProductOptions: false,
      cardTypePriceRequired: [] // 卡片价格必选数组
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    agentId() {
      return parseInt(this.$route.params.id)
    },

    isSaleLogin() {
      return this.$store.getters.userRole === 'sale' || this.$store.getters.userRole === 'sale_assistant'
    }
  },
  created() {
    this.form.getFieldDecorator('keys', { initialValue: [0], preserve: true })
    this.fetchFormData()
  },
  methods: {
    checkDistributorPrice(rule, value, callback) {
      if (this.isSaleLogin && this.form.getFieldValue(`${rule.field.split('.')[0]}.floor_price`) > value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于底价')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    checkExcessDistributorPrice(rule, value, callback) {
      if (this.isSaleLogin && this.form.getFieldValue(`excess_floor_price`) > value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于底价')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    checkStopDistributorPrice(rule, value, callback) {
      if (this.isSaleLogin && this.form.getFieldValue(`stop_floor_price`) > value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于底价')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    checkCardTypePrice(rule, value, callback) {
      if (this.isSaleLogin && this.form.getFieldValue(`${rule.field.split('.')[0]}.floor_price`) > value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于底价')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    checkCycles(rule, value, callback, k, index) {
      if (!value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback()
        return
      }

      const preCycleIndex = this.form.getFieldValue('keys')[index - 1]
      const preCycles = this.form.getFieldValue(`agents_product_favorable_prices[${preCycleIndex}].cycles`)
      if (preCycles && preCycles >= value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('应比上个周期数大!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 校验优惠价周期
    validateFavorablePriceCycles() {
      this.form.getFieldValue('keys').forEach((k) => {
        if (this.form.getFieldValue(`agents_product_favorable_prices[${k}].cycles`)) {
          this.$nextTick(() => {
            this.form.validateFields([`agents_product_favorable_prices[${k}].cycles`], { force: true })
          })
        }
      })
    },

    fetchFormData() {
      this.spinning = true
      findAgentsProductForm(this.id).then((res) => {
        this.data = res.data
        this.isLimitSubscribeCycle = this.data.is_limit_subscribe_cycle
        this.isAllowRetail = this.data.is_allow_retail
        this.isAllowSelfSubscribe = this.data.is_allow_self_subscribe
        this.isPlatformRetailOperator = this.data.is_allow_retail && this.data.retail_operator === 'platform'
        this.IsAllowExceedPool = this.data.is_allow_exceed_pool

        this.favorablePriceSpanSize = this.isPlatformRetailOperator ? 6 : 8
        if (this.isPlatformRetailOperator) {
          this.favorablePriceLabels.push('零售价*')
        }

        if (this.data.capacity_unit === 'count') {
          this.excessRateQuantityUnit = '次'
        }

        this.userBusinessType = this.data.user_business_type_slug
        if (this.userBusinessType === 'custom_day') {
          this.isShowExcessRateItem = false
        } else if (this.userBusinessType === 'merchant_sales') {
          this.isMerchantSales = true
        }

        if (this.userBusinessType === 'custom_month' || this.userBusinessType === 'traffic_share') {
          this.isShowStopItem = true
          this.isCanSetAddonProduct = true
        }

        // 以下模式可以配置零售或自订
        if (this.userBusinessType !== 'traffic_share_package' && this.userBusinessType !== 'merchant_sales') {
          this.isCanSetAllowRetailOrSelfSubscribe = true
        }

        // 以下模式可以配置超池
        if (this.userBusinessType === 'traffic_share') {
          this.isCanSetAllowExceedPackage = true
        }

        this.agentsProductFavorablePrices = res.data.agents_product_favorable_prices
        this.priceIndex = this.agentsProductFavorablePrices.length
        const keys = []
        this.agentsProductFavorablePrices.forEach((value, index) => {
          keys.push(index)
          // 处理零售价值
          if (value.retail_price === -1) {
            value.retail_price = undefined
          }
        })
        this.form.setFieldsValue({
          keys: keys
        })
        // const keys = []
        // for (let i = 0; i < this.agentsProductFavorablePrices.length; i++) {
        //   keys.push(i)
        // }

        var addonProductIds = res.data.addon_product_ids
        this.loadingAddonProductOptions = true
        findAddonProductOptions(this.agentId).then((res) => {
          if (res.code === 0) {
            this.loadingAddonProductOptions = false
            this.addonProductOptions = res.data

            if (addonProductIds) {
              this.form.setFieldsValue({
                addon_product_ids: addonProductIds
              })
            }
          }
        })

        if (this.isSaleLogin) {
          // 销售只显示已经勾选的材质(因为只能设置客户价)
          this.agentsProductsCardTypes = res.data.agents_products_card_types.filter(record => record.id > 0)
        } else {
          this.agentsProductsCardTypes = res.data.agents_products_card_types
        }
        this.agentsProductsCardTypes.forEach((cardType) => {
          this.cardTypePriceRequired.push(cardType.id > 0)
        })

        this.form.setFieldsValue({
          name: this.data.name,
          is_allow_retail: this.data.is_allow_retail,
          retail_operator: this.data.retail_operator,
          is_allow_self_subscribe: this.data.is_allow_self_subscribe,
          is_limit_subscribe_cycle: this.data.is_limit_subscribe_cycle,
          is_allow_exceed_pool: this.data.is_allow_exceed_pool,
          product_description: this.data.product_description,
          excess_rate_quantity: this.data.excess_rate_quantity,
          excess_distributor_price: this.data.excess_distributor_price,
          excess_floor_price: this.data.excess_floor_price,
          stop_distributor_price: this.data.stop_distributor_price,
          stop_floor_price: this.data.stop_floor_price
        })

        this.spinning = false
      })
    },

    // 删除价格设置
    removeSetPriceItem(k) {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      // We need at least one passenger
      if (keys.length === 1) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter(key => key !== k)
      })

      this.validateFavorablePriceCycles()
    },

    // 增加价格设置
    addSetPriceItem() {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      const nextKeys = keys.concat(this.priceIndex++)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys: nextKeys
      })
    },

    checkRetailPrice(rule, value, callback, k) {
      if (!value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback()
        return
      }

      const distributorPrice = this.form.getFieldValue(`agents_product_favorable_prices[${k}].distributor_price`)
      if (value < distributorPrice) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于客户价!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    changeCardType(value, index) {
      this.cardTypePriceRequired[index] = value.length !== 0

      if (value.length === 0) {
        this.$nextTick(() => {
          this.form.validateFields([`agents_products_card_types[${index}].price`], { force: true })
          this.form.validateFields([`agents_products_card_types[${index}].floor_price`], { force: true })
        })
      }
    },

    // 改变是否允许零售
    changeIsAllowRetail(e) {
      if (e.target.checked) {
        this.isAllowRetail = true
      } else {
        this.isAllowRetail = false
      }
    },

    // 改变是否允许自订
    changeIsAllowSelfSubscribe(e) {
      if (e.target.checked) {
        this.isAllowSelfSubscribe = true
      } else {
        this.isAllowSelfSubscribe = false
      }
    },

    // 改变是否允许超池
    changeIsAllowExceedPool(e) {
      if (e.target.checked) {
        this.IsAllowExceedPool = true
      } else {
        this.IsAllowExceedPool = false
      }
    },

    // 改变是否限制订购周期数
    changeIsLimitSubscribeCycle(e) {
      this.isLimitSubscribeCycle = e.target.checked
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      this.form.validateFields((err, values) => {
        if (!err) {
          const { isValid, agentsProductsCardTypes } = this.validAgentsProductsCardTypes(values)
          if (!isValid) {
            this.submitting = false
            return
          }

          const data = {
            ...values,
            agents_product_favorable_prices: values.agents_product_favorable_prices.filter(key => key),
            agents_products_card_types: agentsProductsCardTypes
          }

          checkAgentsProductPrices(this.id,
            { agents_product_favorable_prices: data.agents_product_favorable_prices }).then((res) => {
            if (res.code === 0) {
              if (res.data.is_valid) {
                this.updateProduct(data, this)
              } else {
                const vm = this
                this.$confirm({
                  title: '设置的客户价中存在大于同周期节点下(分销商的企业客户价格)的情况，确定保存吗?',
                  content: '',
                  okText: '确定',
                  okType: 'danger',
                  cancelText: '取消',
                  onOk() {
                    vm.updateProduct(data, vm)
                  }
                })
              }
            }
            this.submitting = false
          })
        } else {
          this.submitting = false
        }
      })
    },

    validAgentsProductsCardTypes(values) {
      let isValid = true
      const agentsProductsCardTypes = []

      if (this.agentsProductsCardTypes.length === 0) {
        this.$error({
          title: '提示',
          content: '当前无卡片材质可勾选，请先维护卡片材质！'
        })
        isValid = false
        // eslint-disable-next-line no-sequences
        return { isValid: isValid, agentsProductsCardTypes: agentsProductsCardTypes }
      }

      values.agents_products_card_types.forEach((cardType) => {
        if (cardType.card_type_id && cardType.card_type_id[0]) {
          agentsProductsCardTypes.push({
            ...cardType,
            card_type_id: cardType.card_type_id[0]
          })
        }
      })

      if (agentsProductsCardTypes.length === 0) {
        this.$error({
          title: '提示',
          content: '请勾选卡片材质'
        })
        isValid = false
      }

      return { isValid: isValid, agentsProductsCardTypes: agentsProductsCardTypes }
    },

    updateProduct(data, _this) {
      updateAgentsProduct(_this.id, data).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          _this.isShow = false
          // 告知父组件已完成
          _this.$emit('completed')
        }
      })
    }
  }
}
</script>
